import React, { useEffect, useRef } from 'react'
import {useTranslation} from 'react-i18next'

import './sakhalin.component.less';

import SakhalinMain from '../../assets/img/sakhalin/sakhalin_main.jpg'
import SakhalinSmall from '../../assets/img/sakhalin/sakhalin_small.jpg'
import SakhalinPoster from "../../assets/img/sakhalin/sakhalin-post.jpg";

import Seo from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const SakhalinComponent = () => {
    const { t, i18n } = useTranslation();
    const { originalPath } = useI18next();

    return (
        <div>
            <Seo description={t("metaDescriptionSakhalin")} lang={i18n.language} title={t("titleSakhalin")} />
            <div className="sakhalin">
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-padding-bottom main-block">
                    <div className="main-block__text">
                        <div className="main-block__title">
                            <span><span
                                dangerouslySetInnerHTML={{__html: t("airQualityMonitoringNetwork")}}/></span>
                            <span
                                className="main-block__title-purple"> <span
                                dangerouslySetInnerHTML={{__html: t("inThePacificIslands")}}/></span>
                        </div>
                        <div className="main-block__description">
                            <span dangerouslySetInnerHTML={{__html: t("sakhalinIslands")}}/>
                        </div>
                    </div>
                    <div className="main-block__img">
                        <div
                            className="main-block__main-img-block main-block__main-img-block--mobile">
                            <div className="main-block__main-img-block--1"/>
                            <div className="main-block__main-img-block--2">
                                <img alt="" className="main-block__main-img"
                                     src={SakhalinMain}/>
                                <div className="main-block__artefact"/>
                            </div>
                        </div>
                        <div
                            className="main-block__small-img-block main-block__small-img-block--mobile">
                            <div className="main-block__small-img-block--1">
                                <img alt=""
                                     className="main-block__small-img"
                                     src={SakhalinSmall}/>
                            </div>
                            <div className="main-block__small-img-block--2"/>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block simple-content">
                    <div className="simple-content__title">
                        <div className="simple-content__col-left">
                            <span
                                dangerouslySetInnerHTML={{__html: t("task")}}/>
                        </div>
                        <div className="simple-content__col-right">

                        </div>
                    </div>
                    <div className="simple-content__description">
                        <div className="simple-content__col-left">
                            <div
                                className="simple-content__description--block simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("localMinistryOfNaturalResources")}}/>
                            </div>
                            <div
                                className="simple-content__description--block simple-content__description--big-size">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("keyClientsRequirements")}}/>
                            </div>
                        </div>
                        <div className="simple-content__col-right">
                            <div
                                className="simple-content__description--block simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("theRegionInitiatedTheNetwork")}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block simple-content">
                    <div className="simple-content__title">
                        <div className="simple-content__col-left">
                            <span
                                dangerouslySetInnerHTML={{__html: t("solution")}}/>
                        </div>
                        <div className="simple-content__col-right">

                        </div>
                    </div>
                    <div className="simple-content__description">
                        <div className="simple-content__col-left">
                            <div
                                className="simple-content__description--block simple-content__description--big-size">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("cityAirDeployedAnAirQuality")}}/>
                            </div>
                            <div
                                className="simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("populationMayGet")}}/>
                                <span
                                    dangerouslySetInnerHTML={{__html: t("andConsiderItInTheForm")}}/>
                            </div>
                        </div>
                        <div className="simple-content__col-right">
                            <div
                                className="simple-content__description--block simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("ministrysOfficialsReceiveMore")}}/>
                            </div>
                            <div
                                className="simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("thisProvidesProper")}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block simple-content">
                    <div className="simple-content__description">
                        <div className="simple-content__col-left">
                            <div className="simple-content__title"><span
                                dangerouslySetInnerHTML={{__html: t("informingPopulation")}}/>
                            </div>
                            <div
                                className="simple-content__description--block simple-content__description--big-size">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("cityAirTeamHasDeveloped")}}/>
                            </div>
                        </div>
                        <div
                            className="borders-block__hr--horizontal borders-block__hr--mobile"/>
                        <div className="simple-content__col-right">
                            <div className="simple-content__title"><span
                                dangerouslySetInnerHTML={{__html: t("dataForOfficialUse")}}/>
                            </div>
                            <div
                                className="simple-content__description--block simple-content__description--big-size">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("dataReceiptProcessionAnd")}}/>
                            </div>
                            <div
                                className="simple-content__description--end-block simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("webApplicationHelps")}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border simple-content simple-content--yellow">
                    <div className="sakhalin__title"><span
                        dangerouslySetInnerHTML={{__html: t("cityScreenAToolForManagerAndEcologist")}}/>
                    </div>
                    <div className="sakhalin__subtitle"><span
                        dangerouslySetInnerHTML={{__html: t("mapAtmosphericProfileOfUrbanArea")}}/>
                    </div>
                    <div className="sakhalin__col">
                        <div className="sakhalin__col--left sakhalin__col--left-description">
                            <div
                                className="simple-content__description--small-text simple-content__description--bold">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("theMapProvidesFullDaily")}}/>
                            </div>
                        </div>
                        <div className="sakhalin__col--right"/>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block_sakhalin wrapper_block__block wrapper_block--padding-bottom">
                    <div
                        className="display-none-mobile"/>
                    <div className="sakhalin__col sakhalin__col-items sakhalin__col--extra-padding">
                        <div className="sakhalin__col--left">
                            <div className="sakhalin__description-content">
                                <div
                                    className="sakhalin__description-content--number">1
                                </div>
                                <div
                                    className="simple-content__description--small-text">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("theMapDisplaysAll")}}/><strong><span
                                    dangerouslySetInnerHTML={{__html: t("allMonitoringPosts")}}/></strong><span
                                    dangerouslySetInnerHTML={{__html: t("ofSakhalinRegion")}}/>
                                </div>
                            </div>
                        </div>
                        <div className="sakhalin__col--right">
                            <div className="sakhalin__description-content">
                                <div
                                    className="sakhalin__description-content--number">2
                                </div>
                                <div
                                    className="simple-content__description--small-text">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("postSignsDemonstrateThe")}}/><strong><span
                                    dangerouslySetInnerHTML={{__html: t("airQualityIndex")}}/></strong><span
                                    dangerouslySetInnerHTML={{__html: t("atTheLocationDefined")}}/><strong><span
                                    dangerouslySetInnerHTML={{__html: t("ammoniumFormaldehydeParticulate")}}/></strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sakhalin__col sakhalin__col-items sakhalin__col--extra-padding">
                        <div className="sakhalin__col--left">
                            <div className="sakhalin__description-content">
                                <div
                                    className="sakhalin__description-content--number">3
                                </div>
                                <div
                                    className="simple-content__description--small-text">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("withTheComparativeOption")}}/><strong><span
                                    dangerouslySetInnerHTML={{__html: t("compareData")}}/></strong><span
                                    dangerouslySetInnerHTML={{__html: t("fromDifferentLocations")}}/>
                                </div>
                            </div>
                        </div>
                        <div className="sakhalin__col--right">
                            <div className="sakhalin__description-content">
                                <div
                                    className="sakhalin__description-content--number">4
                                </div>
                                <div
                                    className="simple-content__description--small-text">
                                    <strong><span
                                        dangerouslySetInnerHTML={{__html: t("macLevelExceedanceNotification")}}/></strong><span
                                    dangerouslySetInnerHTML={{__html: t("exceedanceNotification")}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sakhalin__col sakhalin__col-items sakhalin__col--extra-padding">
                        <div className="sakhalin__col--left">
                            <div className="sakhalin__description-content">
                                <div
                                    className="sakhalin__description-content--number">5
                                </div>
                                <div
                                    className="simple-content__description--small-text">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("cityScreenWebAppAllows")}}/><strong><span
                                    dangerouslySetInnerHTML={{__html: t("adjustNotifications")}}/></strong><span
                                    dangerouslySetInnerHTML={{__html: t("forAnyMACLevelExceedance")}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border simple-content">
                    <div className="simple-content__title">
                        <span
                            dangerouslySetInnerHTML={{__html: t("networkAdministrationAndManagement")}}/>
                    </div>
                    <div className="simple-content__description">
                        <div className="simple-content__col-left">
                            <div
                                className="simple-content__description--block simple-content__description--big-size">
                                <strong><span
                                    dangerouslySetInnerHTML={{__html: t("cityAirTakesFullResponsibility")}}/></strong>
                            </div>
                        </div>
                        <div className="simple-content__col-right">
                            <div
                                className="simple-content__description--block simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("notificationCentreAllowsToaAdjust")}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SakhalinComponent.propTypes = {}

SakhalinComponent.defaultProps = {}

export default SakhalinComponent
